import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b0a02496 = () => interopDefault(import('../pages/404-path.vue' /* webpackChunkName: "pages/404-path" */))
const _0606219e = () => interopDefault(import('../pages/agb.vue' /* webpackChunkName: "pages/agb" */))
const _c13d72d2 = () => interopDefault(import('../pages/benachrichtigungen.vue' /* webpackChunkName: "pages/benachrichtigungen" */))
const _04fd7fa8 = () => interopDefault(import('../pages/bibliothek.vue' /* webpackChunkName: "pages/bibliothek" */))
const _29a9b6d8 = () => interopDefault(import('../pages/datenschutz.vue' /* webpackChunkName: "pages/datenschutz" */))
const _1b265e72 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3bc44332 = () => interopDefault(import('../pages/einstellungen.vue' /* webpackChunkName: "pages/einstellungen" */))
const _02c51502 = () => interopDefault(import('../pages/hilfe.vue' /* webpackChunkName: "pages/hilfe" */))
const _15ac276c = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _87d71844 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _7e313205 = () => interopDefault(import('../pages/profiles.vue' /* webpackChunkName: "pages/profiles" */))
const _53b63185 = () => interopDefault(import('../pages/raisenow.vue' /* webpackChunkName: "pages/raisenow" */))
const _bb462844 = () => interopDefault(import('../pages/registrieren.vue' /* webpackChunkName: "pages/registrieren" */))
const _1fa5df10 = () => interopDefault(import('../pages/team.vue' /* webpackChunkName: "pages/team" */))
const _38f50c64 = () => interopDefault(import('../pages/pfad/ai-neu.vue' /* webpackChunkName: "pages/pfad/ai-neu" */))
const _405f837a = () => interopDefault(import('../pages/pfad/neu.vue' /* webpackChunkName: "pages/pfad/neu" */))
const _9239477c = () => interopDefault(import('../pages/pfad/_ref/index.vue' /* webpackChunkName: "pages/pfad/_ref/index" */))
const _6564cd86 = () => interopDefault(import('../pages/profil/_username.vue' /* webpackChunkName: "pages/profil/_username" */))
const _2f5a9f82 = () => interopDefault(import('../pages/pfad/_ref/admin.vue' /* webpackChunkName: "pages/pfad/_ref/admin" */))
const _1be23d56 = () => interopDefault(import('../pages/pfad/_ref/editAdmin.vue' /* webpackChunkName: "pages/pfad/_ref/editAdmin" */))
const _b0c57d5a = () => interopDefault(import('../pages/pfad/_ref/vorschau.vue' /* webpackChunkName: "pages/pfad/_ref/vorschau" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404-path",
    component: _b0a02496,
    name: "404-path___de___default"
  }, {
    path: "/agb",
    component: _0606219e,
    name: "agb___de___default"
  }, {
    path: "/benachrichtigungen",
    component: _c13d72d2,
    name: "benachrichtigungen___de___default"
  }, {
    path: "/bibliothek",
    component: _04fd7fa8,
    name: "bibliothek___de___default"
  }, {
    path: "/datenschutz",
    component: _29a9b6d8,
    name: "datenschutz___de___default"
  }, {
    path: "/de",
    component: _1b265e72,
    name: "index___de"
  }, {
    path: "/einstellungen",
    component: _3bc44332,
    name: "einstellungen___de___default"
  }, {
    path: "/fr",
    component: _1b265e72,
    name: "index___fr"
  }, {
    path: "/hilfe",
    component: _02c51502,
    name: "hilfe___de___default"
  }, {
    path: "/impressum",
    component: _15ac276c,
    name: "impressum___de___default"
  }, {
    path: "/login",
    component: _87d71844,
    name: "login___de___default"
  }, {
    path: "/profile",
    component: _7e313205,
    name: "profiles___de___default"
  }, {
    path: "/raisenow",
    component: _53b63185,
    name: "raisenow___de___default"
  }, {
    path: "/registrieren",
    component: _bb462844,
    name: "registrieren___de___default"
  }, {
    path: "/team",
    component: _1fa5df10,
    name: "team___de___default"
  }, {
    path: "/de/404-path",
    component: _b0a02496,
    name: "404-path___de"
  }, {
    path: "/de/agb",
    component: _0606219e,
    name: "agb___de"
  }, {
    path: "/de/benachrichtigungen",
    component: _c13d72d2,
    name: "benachrichtigungen___de"
  }, {
    path: "/de/bibliothek",
    component: _04fd7fa8,
    name: "bibliothek___de"
  }, {
    path: "/de/datenschutz",
    component: _29a9b6d8,
    name: "datenschutz___de"
  }, {
    path: "/de/einstellungen",
    component: _3bc44332,
    name: "einstellungen___de"
  }, {
    path: "/de/hilfe",
    component: _02c51502,
    name: "hilfe___de"
  }, {
    path: "/de/impressum",
    component: _15ac276c,
    name: "impressum___de"
  }, {
    path: "/de/login",
    component: _87d71844,
    name: "login___de"
  }, {
    path: "/de/profile",
    component: _7e313205,
    name: "profiles___de"
  }, {
    path: "/de/raisenow",
    component: _53b63185,
    name: "raisenow___de"
  }, {
    path: "/de/registrieren",
    component: _bb462844,
    name: "registrieren___de"
  }, {
    path: "/de/team",
    component: _1fa5df10,
    name: "team___de"
  }, {
    path: "/fr/404-path",
    component: _b0a02496,
    name: "404-path___fr"
  }, {
    path: "/fr/aide",
    component: _02c51502,
    name: "hilfe___fr"
  }, {
    path: "/fr/bibliotheque",
    component: _04fd7fa8,
    name: "bibliothek___fr"
  }, {
    path: "/fr/cgv",
    component: _0606219e,
    name: "agb___fr"
  }, {
    path: "/fr/confidentialite",
    component: _29a9b6d8,
    name: "datenschutz___fr"
  }, {
    path: "/fr/impressum",
    component: _15ac276c,
    name: "impressum___fr"
  }, {
    path: "/fr/login",
    component: _87d71844,
    name: "login___fr"
  }, {
    path: "/fr/notifications",
    component: _c13d72d2,
    name: "benachrichtigungen___fr"
  }, {
    path: "/fr/parametres",
    component: _3bc44332,
    name: "einstellungen___fr"
  }, {
    path: "/fr/profils",
    component: _7e313205,
    name: "profiles___fr"
  }, {
    path: "/fr/raisenow",
    component: _53b63185,
    name: "raisenow___fr"
  }, {
    path: "/fr/registrieren",
    component: _bb462844,
    name: "registrieren___fr"
  }, {
    path: "/fr/team",
    component: _1fa5df10,
    name: "team___fr"
  }, {
    path: "/pfad/ai-neu",
    component: _38f50c64,
    name: "pfad-ai-neu___de___default"
  }, {
    path: "/pfad/neu",
    component: _405f837a,
    name: "pfad-neu___de___default"
  }, {
    path: "/de/pfad/ai-neu",
    component: _38f50c64,
    name: "pfad-ai-neu___de"
  }, {
    path: "/de/pfad/neu",
    component: _405f837a,
    name: "pfad-neu___de"
  }, {
    path: "/fr/pfad/ai-nouveau",
    component: _38f50c64,
    name: "pfad-ai-neu___fr"
  }, {
    path: "/fr/pfad/nouveau",
    component: _405f837a,
    name: "pfad-neu___fr"
  }, {
    path: "/de/pfad/:ref",
    component: _9239477c,
    name: "pfad-ref___de"
  }, {
    path: "/de/profil/:username?",
    component: _6564cd86,
    name: "profil-username___de"
  }, {
    path: "/fr/pfad/:ref",
    component: _9239477c,
    name: "pfad-ref___fr"
  }, {
    path: "/fr/profil/:username?",
    component: _6564cd86,
    name: "profil-username___fr"
  }, {
    path: "/de/pfad/:ref?/admin",
    component: _2f5a9f82,
    name: "pfad-ref-admin___de"
  }, {
    path: "/de/pfad/:ref?/editAdmin",
    component: _1be23d56,
    name: "pfad-ref-editAdmin___de"
  }, {
    path: "/de/pfad/:ref?/vorschau",
    component: _b0c57d5a,
    name: "pfad-ref-vorschau___de"
  }, {
    path: "/fr/pfad/:ref?/admin",
    component: _2f5a9f82,
    name: "pfad-ref-admin___fr"
  }, {
    path: "/fr/pfad/:ref?/editAdmin",
    component: _1be23d56,
    name: "pfad-ref-editAdmin___fr"
  }, {
    path: "/fr/pfad/:ref/apercu",
    component: _b0c57d5a,
    name: "pfad-ref-vorschau___fr"
  }, {
    path: "/pfad/:ref",
    component: _9239477c,
    name: "pfad-ref___de___default"
  }, {
    path: "/profil/:username?",
    component: _6564cd86,
    name: "profil-username___de___default"
  }, {
    path: "/pfad/:ref?/admin",
    component: _2f5a9f82,
    name: "pfad-ref-admin___de___default"
  }, {
    path: "/pfad/:ref?/editAdmin",
    component: _1be23d56,
    name: "pfad-ref-editAdmin___de___default"
  }, {
    path: "/pfad/:ref?/vorschau",
    component: _b0c57d5a,
    name: "pfad-ref-vorschau___de___default"
  }, {
    path: "/",
    component: _1b265e72,
    name: "index___de___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
