

export default () => {
    if (process.server) {
        return;
    }

    const detect = () => typeof Object !== 'undefined' && 'entries' in Object;

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "object.entries"
        ], function (require) {
            const polyfill = require("object.entries");

            const install = (entries) => entries.shim();

            install(polyfill);

            resolve();
        });
    });
}