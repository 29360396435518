import Vue from 'vue';

import {
  AlertPlugin,
  LayoutPlugin,
  NavbarPlugin,
  SidebarPlugin,
  IconsPlugin,
  FormPlugin,
  FormSelectPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormCheckboxPlugin,
  InputGroupPlugin,
  ButtonPlugin,
  SpinnerPlugin,
  ModalPlugin,
  LinkPlugin
} from 'bootstrap-vue';

Vue.use(AlertPlugin);
Vue.use(LayoutPlugin);
Vue.use(NavbarPlugin);
Vue.use(SidebarPlugin);
Vue.use(IconsPlugin);
Vue.use(FormPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormFilePlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(InputGroupPlugin);
Vue.use(ButtonPlugin);
Vue.use(SpinnerPlugin);
Vue.use(ModalPlugin);
Vue.use(LinkPlugin);
